

@font-face {
  font-family: 'pantasia';
  src: url("Type/Pantasia-Regular.ttf");
}

@font-face {
  font-family: 'PantasiaItalic';
  src: url("Type/Pantasia-Italic.otf");
}

@font-face {
  font-family: 'Unica';
  src: url("Type/NeueHaasUnica-Medium.ttf");
  font-style: normal;
}

@font-face {
  font-family: 'passenger';
  src: url("Type/Passenger-Regular-Web.woff");
  font-style: normal;
}

@font-face {
  font-family: 'passengerbold';
  src: url("Type/Passenger-Bold-Web.woff");
  font-style: normal;
}

:root {
  --mobileMenuDisplay: inline-block;
  --color: black;
  --menuZIndex: 10;
  --leftWidth: 50%;
  --rightWidth: auto;
  --aboutWidth: 50vw;
  --ackFont: 2em;
  --printWidth: 50vw;
  --fontFace: "neue-haas-unica", Arial;
  /* --fontFace: "passenger"; */
  --fontWeight: 500;
  --fontWeightDesc: 300;
  --fontDesc: 'neue-haas-unica';
  --fontSize: 1em;
  --fontSizeDesc: 0.7em;
  --lineHeight: 1;
  --indent: 0;
}


a {
  width:fit-content;
}

a:hover {
  background-color: black;
  color:white;
  cursor: pointer;
}

header {
  position:fixed;
  box-sizing: border-box;
  padding:10px;
  z-index:2;
}

.screen-func-header {
  /*position:fixed;*/
  box-sizing: border-box;
  /*padding:10px;*/
  z-index: 99999999;
  display: var(--mobileMenuDisplay);
  text-align: right;
  cursor: pointer;
  font-size: var(--fontSize);
}

footer {
  position:fixed;
  box-sizing: border-box;
  padding:10px;
  z-index: 2;
  right:0;
  text-align: right;
}

.help-text-individual{
  display: inline-block;
  animation-name: floating;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: large;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}


.help-text-container{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 6;
}

.print-loading-container{
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  position: absolute;
  z-index: 100;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
  line-height: 100vh;
}

.print-loading-text{
  font-size: x-large;
  color: white;
  /*background-color: black;*/
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50vh;
}

.fact-times {
  font-family: var(--fontDesc);
  font-size: 0.7em;
  margin: 0;
  text-align: right;
  float: right;
  clear: both;
  background-color: transparent;
}


.drag-div{
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.bottom-of-page{
  padding:10px;
  z-index: var(--menuZIndex);
  position:absolute;
  top: -0.1em;
  /*width: 20%;*/
  width: auto;
  /*width: 50%;*/
  right:0;
}

.mobile-bottom-of-page{
  padding:10px;
  z-index: 99;
  position:absolute;
  bottom: -0.1em;
  /*width: 50%;*/
  width: auto;
  right:0;
  display: var(--mobileMenuDisplay);
  
}

.scrolling-banner-child{
  height: 1em;
  width: auto;
  background-color: transparent;
  padding: 0 0 0 0;
  margin-top:-1em;
  line-height: var(--lineHeight);
  text-align: right;
}

.scrolling-banner-child:hover{
  background-color: rgb(0, 0, 0);
  color:white;
}

.scrolling-banner-parent{
  width: auto;
  height: 100%;
  background-color: none;
  overflow: hidden;
  z-index: 9999;
  height:5em;

}

.index-dt{
  padding-bottom: 5px;
}

.index-tiny-img {
  height:20px;
  width:20px;
  display: inline;
}

.index-times {
  font-family: Century;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
  display: inline;
  padding-left: 5px;
  vertical-align:text-top;
}

.times {
  font-family: Century;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
}

.helvetica {
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  font-size: var(--fontSize);
  cursor: pointer;
  z-index: var(--menuZIndex);
  position: relative;
  pointer-events: all;
  
}

.helvetica-mobile {
  text-align: right;
  right: 0;
  margin: 0 0 0 0;
}

/* .index-main-paper {
  position:absolute;
  display:none; 
  padding:20px;
  width:100vw;
  height:100%;
  overflow: scroll;
  mix-blend-mode: darken;
  background-size: 33%;
  background-color: whitesmoke;
} */

.focus-BG {
  position:absolute;
  box-sizing: border-box;
  max-width:50vw;
  height:100%;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 9999;
  user-select: none;
  /* border-left: 0.1px solid black; */
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);

  -ms-overflow-style: none;  
  scrollbar-width: none; 
  box-shadow: 195px 0px 126px 100px #000000;
}
.focus-BG::-webkit-scrollbar { 
  display: none; 
}

.focus-BG-mobile {
  position:absolute;
  box-sizing: border-box;
  /*max-width:50vw;*/
  width: 100vw;
  height:100%;
  overflow: scroll;
  overflow-x: hidden;
  z-index: var(--menuZIndex);
  user-select: none;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);

  -ms-overflow-style: none;  
  scrollbar-width: none; 
}
.focus-BG-mobile::-webkit-scrollbar { 
  display: none; 
}

.index-main-BG {
  position:absolute;
  padding:20px;
  width:100vw;
  height:100%;
  overflow: scroll;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: 33%;
  
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.index-main {
  position:absolute;
  width:100vw;
  height:100vh;
  overflow: hidden;
  z-index: 10;
}

.index-content {
  position:absolute;
  top:10vh;
  line-height: var(--lineHeight);
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
}

.index-img img {
  width: 30vw;
  max-height:auto;
}

.index-line {
  padding-top: 12px;
  padding-bottom: 12px;
}

.index-right {
  position:absolute;
  height:100%;
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  padding:20px;
  right:0;
  overflow: scroll;
}

/* new additions 06 05 2023 */

* {
  text-rendering: geometricPrecision;
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  line-height: var(--lineHeight);
}

html, body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: rgb(255, 255, 255);
  /* -webkit-text-stroke: 0.1px rgb(0, 0, 0); */

}

.content {
  /*height: 100vh;*/
  height: 95vh;
  z-index: 10;
  position: relative;
}

.left {
  float: left;
  display:flex;
  flex-direction: column;
  width: var(--leftWidth);
  height: 100vh;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0.5em;
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  user-select: none;
  font-size: var(--fontSize);
}

.left::-webkit-scrollbar { 
  display: none; 
}

.left .title-container {
  position: fixed;
  z-index: var(--menuZIndex);
}

.bigDragImg {
  max-height: 60em;
  max-width: 60em;
  height:auto;
  width:auto;
  cursor: move;
  position:block;
}

@media only screen and (max-device-width : 1024px) { 
  .bigDragImg {
    max-height: 40em;
    max-width: 40em;
  }

}

.dragImg {
  max-height: 15em;
  max-width: 15em;
  height:auto;
  width:auto;
  cursor: move;
  position:block;
}

.dragImgIndex {
  font-size: 0.7em;
}

.dragImgIndexLine {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}

.left .search-bar {
  font-size: 1em;
  padding:0.5em;
  transform:translateX(-0.5em);
  border: none;
  background-color: transparent;
}

.left .search-bar:focus {
  outline:none;
}

.left .student {
  /*display:block;*/
  color: var(--color);
  display: var(--mobileMenuDisplay);
  z-index: var(--menuZIndex);
  position: relative;
}

.menu-to-hide {
  color: var(--color);
  display: var(--mobileMenuDisplay);
  z-index: var(--menuZIndex);
  position: relative;
}

.left .student-names {
  margin-top: 6vh;
  margin-bottom: 15vh;
  user-select: none;
}

.left .student-names a:hover {
  color:rgb(255, 255, 255);
  background-color: black !important;
}

.left .student-expand {
  display:none;
  flex-direction: column;
  height:auto;
  box-sizing: border-box;
  padding:0.5em;
}

.left .student-expand img {
  max-width:15em;
  height:auto;
}

.focus-obscure {
  height:100vh;
  width:50vw;
  position:fixed;
  left:0;
  top:0;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index:var(--menuZIndex);
  pointer-events: none;
}

.focus-Img {
  width: 100%;
  padding: 0 0 0.5em 0;
  user-select: none;
}

.focus-Header {
  padding: 0.5em 0.5em 0.5em 0.5em;
  float: left;
  user-select: none;
  color:rgb(0, 0, 0);
  font-size: var(--fontSizeDesc);
  
}
.focus-Header-mobile {
  padding: 0.5em 0.5em 0.5em 0.5em;
  float: left;
  user-select: none;
  color:rgb(0, 0, 0);
  font-size: var(--fontSizeDesc);

  
  /*position: fixed;*/
}

.focus-Header-container {
  width: var(--aboutWidth);
  /*width: 100%; I do not know why this was the problem with close disapearing, it was going full page width.*/
  position: fixed;
  background-color:rgb(255, 255, 255);
  top: 0px;
  position: fixed;
}

.focus-Close {
  padding: 0.5em 0.5em 0.5em 0.5em;
  user-select: none;
  color:rgb(0, 0, 0);
  position: fixed;
  right: 0;
  top: 0;
  font-size: var(--fontSizeDesc);

}

.focus-Close-mobile {
  padding: 0.5em 0.5em 0.5em 0.5em;
  user-select: none;
  color:rgb(0, 0, 0);

  position: fixed;
  right: 0;
  top: 0;
  font-size: var(--fontSizeDesc);

}

.focus-Close:hover {
  background-color: rgb(0, 0, 0);
  color:rgb(255, 255, 255);
}

.focus-Desc {
  font-family: var(--fontDesc);
  padding: 0 0.5em 0.5em 0.5em;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
  margin-top: 0px;
  text-align: left;
  user-select: none;
  color:rgb(0, 0, 0);
  white-space: pre-wrap;
  text-indent: var(--indent);
}

.focus-Title {
  font-weight: 500;
  padding: 0 0 0 0.5em;
}

.focus-Desc a {
  text-decoration: none;
  color:black;
  font-weight: var(--fontWeight);

}
.focus-Desc a:hover{color:white;}

.focus-Bio {
  font-family: var(--fontDesc);
  padding: 0 0.5em 0.5em 0.5em;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
  margin-top: 0px;
  text-align: left;
  user-select: none;
  color:rgb(0, 0, 0);
  white-space: pre-wrap;
  text-indent: var(--indent);
  font-style: italic;
}


.right {
  display:flex;
  position:absolute;
  right:0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: var(--rightWidth);
  height: 100vh;
  box-sizing: border-box;
  padding: 0.5em;
  
  /*z-index: var(--menuZIndex);*/
} 

.mobile-menu-background{
  background-color: rgba(255, 255, 255, 0.9);
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  display: var(--mobileMenuDisplay);
  pointer-events: none;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /*--mobileMenuDisplay: block;*/
}

#draggable {
  /*position: absolute;*/
  z-index: 9;
  cursor: move;
}

.ack-bg {
  width:100vw;
  height:100vh;
  position:fixed;
  background-color: rgb(0, 0, 0);
  box-sizing: border-box;
  padding:1em;
  cursor: pointer;
  
  z-index: 999999999;
}

.ack-text {
  font-family: 'Unica', Arial;
  font-weight: var(--fontWeight);
  font-size: var(--ackFont);
  color:white;
  text-indent: var(--indent);

}

.about-container {
  height:100vh;
  width:var(--aboutWidth);

  box-sizing: border-box;
  line-height: var(--lineHeight);
  z-index: 1;

  overflow-x: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none;  
  scrollbar-width: none; 
}

.about-container::-webkit-scrollbar { 
  display: none; 
}

.about-title {
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  font-size: var(--fontSizeDesc);
  padding:0.5em 0.5em 0 0.5em;
  white-space: pre-wrap;
}

.about-body {
  font-family: var(--fontDesc);
  font-weight: var(--fontWeightDesc);
  font-size: var(--fontSizeDesc);
  text-align: left;
  padding:0 0.5em 0 0.5em;
}

.about-body a{
  text-decoration: none;
  font-weight: var(--fontWeightDesc);
  color:black;
}

.about-body a:hover{color:white;}

.print-container {
  height:100vh;
  width:100vw;
  top:0;
  position:fixed;
  box-sizing: border-box;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
  z-index: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: black;
}

.print-title {
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  padding:0.5em 0.5em 0 0.5em;
  color:white;
}
.print-title a:hover{background-color: white; color:black}

.print-return {
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  padding:0.5em 0.5em 0.5em 0.5em;
  color:white;
  position:absolute;
  right:0;
  top:0;
}

.print-confirm {
  font-family: var(--fontFace);
  font-weight: var(--fontWeight);
  padding:0.5em 0.5em 0.5em 0.5em;
  color:white;
}
.print-return:hover{background-color: white; color:black}

.print-body {
  font-family: var(--fontDesc);
  text-align: left;
  box-sizing: border-box;
  padding:0 0.5em 0 0.5em;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
  width: var(--printWidth);
  color:white;
  text-indent: var(--indent);

}

.print-container input {
  font-size: var(--fontSize);
  padding:0.5em;
  border: none;
  background-color: transparent;
  width:var(--printWidth);
  color:white;
}

.print-container input {
  outline:none;
}

.print-footer {
  font-family: var(--fontDesc);
  text-align: left;
  padding:0 0.5em 0 0.5em;
  box-sizing: border-box;
  font-size: var(--fontSizeDesc);
  font-weight: var(--fontWeightDesc);
  width: var(--printWidth);
  color:white;
  position:absolute;
  bottom:1em;

}

@media only screen and (max-device-width : 1024px) { 
  

}